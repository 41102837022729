
body {
    font: ageo;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

  }
  
  .land {
    /* max-width: 1200px; */
    width: 100%;
    margin: auto;
    padding: 20px;
    background-color: transparent;
  }
  .category-heading{
    text-align: left; 
    color: #031D5B;
    font-family: Eau Sans Bold,sans-serif;
    font-weight: 800;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: 0px;
    width: 437px;
    height: 65px;
 
    margin-top: 8%;


  }
  
  /* Top Navigation */
  .top-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    position:sticky;
    top: 0;
    z-index: 1;
    padding: 0px 10vw 0px 10vw;

    height: 64px;


    
  }
  .modal-overlay2 {

    height: 40%;
    max-width: 50%;
    z-index: 10000;
    position: relative;
    top: 100px;
    left: 40%;
    background:transparent;
  }
  
  .Logo {
    height: 60px;
  }
  .buttons1{
    background-color: #1E99D7;
    border: none;
    padding: 2px;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;

  }
  .title{
    font-size: 48px;
    text-align: left;
    font-weight:900;
    color: #3AB7E5;
    padding: 30px 70px 20px 0px;
    font: Ageo;
    line-height: 57.6px;
    letter-spacing: 0px;
   
  }
  .content7{
    color: #031D5B;
    text-align: left;
    font-family: Ageo;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0px;
    width: 100%;
    padding: 0%;

  }
  .logo1{
    
    background: none;
    border: none;
  }
  .p1{
    padding: 0;
    justify-content: space-between;
  }
  .changelanguage {
    display: flex;
    gap: 15px;
  }
  
  .changelanguage button {
    background: none;
    border: none;
    color: #031D5B;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }
  .readmore-button{
    font-size: 10px;
  font-weight: bold;
  color:black;
  text-decoration: none;
  float: right;
  border: 1px solid white;
  background-color: white;
  /* border-radius: 20px; */
  width: 100%;
  padding: 2px;
  }
  
  /* Main Content */
  .maincontent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0px 0;
    flex-direction: row;
    /* padding: 0 20px; */
  }
  
  /* Left Content */
  .card-left {
    flex: 1;    
    max-width: 100%;
    margin-left: 11%;
    align-items: flex-start;
    justify-content: flex-end
  }
  
  /* Right Image */
  .card-right {
    flex: 1;
    max-width: 100%;
    text-align: center;
    
  }
  .left-section {
    margin-left: 4.5%;
    max-width: 70%;
  }
  
  .card-right img {
    
    height: auto;
    border-radius: 8px;
    width: fit-content;
  }
  /* Search Bar */
  .topsearch-bar1 {
    width: 100%;
    padding: 10px;
    top: 195px;
    
  }
  .search-input3 {
    width: 100%;
    height: 60px;
    padding: 16px 24px;
    border: 1.5px solid #55C0E7;
    border-radius: 5px;
    background: #FFFFFF;
    font-family: Encode Sans,sans-serif;   
    box-shadow: 0px 1px 5px 0px #00000027 inset;
    background: url('../assets/Search.png') no-repeat 97%  center;
    
  }
  
  .search-input1 {
    width: 100%;
    height: 60px;
    padding: 16px 24px;
    border: 1.5px solid #55C0E7;
    border-radius: 5px;
    background: #FFFFFF;
    font-family: Encode Sans,sans-serif;   
    margin-top: 15%;
    box-shadow: 0px 1px 5px 0px #00000027 inset;
    background: url('../assets/Search.png') no-repeat 97%  center;
    
  }
  .search-input1::placeholder{
    font-family: Encode Sans,sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #B6BBC9;
    width: 200px;
  


  }

  .search-input2 {
    width: 50%;
    padding: 10px 24px;
    font-size: 20px;
    border: 1.5px solid #55C0E7;
    border-radius: 5px;
    background: #FFFFFF;
    color: #B6BBC9;
    font-weight: 700;
    margin-top: 0px;
    align-items: center;
  }
  
 

  
  /* Category Cards */
  .cardstosp {
    padding-top: 20px;
  }
  
  .card-container1 {
    display: flex;
    align-items: stretch;
    position: relative;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 89%;
    margin-left: 11%;
   bottom: 10%;
   top: -2vh;
   margin-right: 10%;
  }
  .card-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    background-color: transparent;
    
  }
  .search-status {
    font-size: 14px;
    color: #3498db;
    margin-top: 5px;
  }
  
  .card1 {
    width: 267px  !important;
    overflow: hidden;
    border: none;
    height: fit-content;
    background-color: transparent;
    transition: transform 0.3s ease-in-out;
    flex: 1 1 calc(25.33% ); 

  }
  
  .card1:hover {
    transform: scale(1.05);
  }
  
  .card1 img {
    width: 100%;
    object-fit: cover; 
    background-color: transparent;
  }
  
  .card-content {
    padding: 15px;
    text-align: left;
    background-color: transparent;
    border: none; /* Ensures no borders */
    background: none; /* Removes any background if needed */
    box-shadow: none;
    color:#031D5B ;
    font-size: large;
  }
  
  .card1 h5 {
    width: 303px;
    height: 35px;

    background-color: transparent;
    font-family: Encode Sans;
    font-weight: 900;
    font-size: 28px;
    line-height: 35px;
    letter-spacing: 0%;
    color: #012B45;


  }
  
  .card1 p {
    color: #868686;
    background-color: transparent;
    padding: 10px 0px 10px 0px;
    font-family: DM Sans;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0%;


  }
  
  .card1 a {
    display: inline-block;
    text-decoration: none;
    background: #3AB7E5;

    color: #fff;
    padding: 10px 15px;
    border-radius: 30px;
    margin-top: 10px;
    width: 220px;
    text-align: center;
    
  }
  .button-container1{
    align-items: flex-start;
    display: flex;
    margin-top:2% ;
    gap: 15px;
    padding-bottom: 30px;

  }
  .auth-button1 {
    background-color: #1E99D7;
    border: none;
    color: #ffffff;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bolder;
    width: 105px;
    height: 64px;
    top: 635px;
    left: 207px;
    gap: 8px;
    padding-top: 16px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 24px;
    border-radius: 16px;
      
}
.auth-button2{
  background-color: #031D5B;
    border: none;
    color: #ffffff;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bolder;
    width: 105px;
    height: 64px;
    top: 635px;
    left: 207px;
    gap: 8px;
    padding-top: 16px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 24px;
    border-radius: 16px;

    
}
/* styles.css */
.badge-container {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 35px 0px 35px 0px;
  color: #031D5B;
  
  
}

.badge-link {
  display: flex;
  align-items: center;
  font-weight: bold;
  color:#031D5B;
  font-size: 13px;
}

.badge-icon {
  background-color: #29a9e1;
  color: white;
  padding: 4px;
  font-size: 14px;
  margin-right: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 6px;
}
.chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 12px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chat-icon {
  color: white;
  width: 24px;
  height: 24px;
}

.chat-box {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 300px;
  background: linear-gradient(to bottom, #29a9e1 50%, white 50%);
  /* background-color: #29a9e1; */
  color: white;
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chat-title {
  font-size: 18px;
  font-weight: bold;
}

.chat-message {
  font-size: 14px;
  margin-top: 8px;
}

.chat-card {
  display: flex;
  align-items: center;
  background-color: black;
  color: white;
  padding: 12px;
  border-radius: 8px;
  margin-top: 12px;
}

.chat-middle-card{
  background-color: black;
  border-radius: 18px;
  height: 25vh;
}

.chat-avatar {
  width: 32px;
  height: 32px;
  background-color: gray;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-label {
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.chat-time {
  font-size: 12px;
  color: gray;
}

.chat-btn {
  width: 60%;
  padding: 4px;
  font-size: small;
  margin-top: 8px;
  background-color: #29a9e1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-btn1 {
  width: 60%;
  padding: 4px;
  font-size: small;
  margin-top: 8px;
  background-color: #29a9e1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-btn:hover {
  background-color: #29a9e1;
}

.chat-recent {
  margin-top: 16px;
}

.chat-recent-item {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 8px;
  border-radius: 8px;
  color: white;
}


  
  /* Responsive Design */
  @media (min-width: 1201px) and (max-width: 1400px) {
    .top-nav{
      width: 100%;
      padding: 10px;
    }
    .card-container1 {
    width: 100%;
    margin-top: -2vh;

}
 .button-container1{
  margin-top:1% ;
}
.category-heading{
  margin-top: 12%;
        
}
.maincontent {
  flex-direction: row;
  
}
}

 
@media (max-width: 1200px) {
  .top-nav{
    width: auto;
      padding: 10px;
      flex-direction: row;
      margin-left: 11%;
  }
  .maincontent {
    flex-direction: row !important;
    max-width: 100%;
    
}
.card-right{
  flex: 1;
  max-width: 45%;
  text-align: center;
}
.card-right img{
  height: 100%;
  border-radius: 8px;
  width: 100%;

}
.card1 a {
  display: inline-block;
  text-decoration: none;
  background: #3AB7E5;

  color: #fff;
  padding: 10px 15px;
  border-radius: 30px;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  
}
.card-container1 {
    width: 100%;  

}

 .button-container1{
  margin-top:2% ;
}

.card-left {
    margin-left: 0;
    max-width: 44%;
    margin-left: 11%;
}


.button-container1{
  align-items: center;
  display: flex;
  margin-top:2% ;
}
.category-heading{
  font-size: 34px;    
  margin-top:auto;
  width: 100%;
  text-align: left;
  
}

}

@media (max-width: 750px) {
  .top-nav{
    width: auto;
      padding: 10px;
      flex-direction: row;
      margin-left: 11%;
  }
  .maincontent {
    flex-direction: row !important;
    max-width: 100%;
    
}
.card-right{
  flex: 1;
  max-width: 45%;
  text-align: center;
}
.card-right img{
  height: 100%;
  border-radius: 8px;
  width: 100%;

}
.card1 a {
  display: inline-block;
  text-decoration: none;
  background: #3AB7E5;

  color: #fff;
  padding: 10px 15px;
  border-radius: 30px;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  
}
.card-container1 {
    width: 100%;  

}

 .button-container1{
  margin-top:2% ;
}

.card-left {
    margin-left: 0;
    max-width: 44%;
    margin-left: 11%;
}


.button-container1{
  align-items: center;
  display: flex;
  margin-top:2% ;
}
.category-heading{
  font-size: 30px;    
  margin-top:auto;
  width: 100%;
  text-align: left;
  
}


}

@media (max-width: 600px) {
  .top-nav{
    width: auto;
      padding: 10px;
      flex-direction: row;
      margin-left: 11%;
  }
  .maincontent {
    flex-direction: row !important;
    max-width: 100%;
    
}
.card-right{
  flex: 1;
  max-width: 45%;
  text-align: center;
}
.card-right img{
  height: 100%;
  border-radius: 8px;
  width: 100%;

}
.card1 a {
  display: inline-block;
  text-decoration: none;
  background: #3AB7E5;

  color: #fff;
  padding: 10px 15px;
  border-radius: 30px;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  
}
.card-container1 {
    width: 100%;  

}

 .button-container1{
  margin-top:2% ;
}

.card-left {
    margin-left: 0;
    max-width: 44%;
    margin-left: 11%;
}


.button-container1{
  align-items: center;
  display: flex;
  margin-top:2% ;
}
.category-heading{
  font-size: 25px;    
  margin-top:auto;
  width: 100%;
  text-align: left;
  
}

}

@media (max-width: 500px) {
  .top-nav{
    width: auto;
      padding: 10px;
      flex-direction: row;
      margin-left: 11%;
  }
  .maincontent {
    flex-direction: row !important;
    max-width: 100%;
    
}
.card-right{
  flex: 1;
  max-width: 45%;
  text-align: center;
}
.card-right img{
  height: 100%;
  border-radius: 8px;
  width: 100%;

}
.card1 a {
  display: inline-block;
  text-decoration: none;
  background: #3AB7E5;

  color: #fff;
  padding: 10px 15px;
  border-radius: 30px;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  
}
.card-container1 {
    width: 100%;  

}

 .button-container1{
  margin-top:2% ;
}

.card-left {
    margin-left: 0;
    max-width: 44%;
    margin-left: 11%;
}


.button-container1{
  align-items: center;
  display: flex;
  margin-top:2% ;
}
.category-heading{
  font-size: 15px;    
  margin-top:auto;
  width: 100%;
  text-align: left;
  
}

}
@media (max-width: 480px) {
  .top-nav{
      flex-direction: row;
      margin-left: 11%;
      padding: 10px;
  }
  .Logo{
    height: 35px;
    align-items: flex-start;
  
  }
  .button-container1{
    display: flex;
  }
  .button-container{
    gap: 8px;
  }
  .auth-button{
    font-size: medium;
  }
  .card-right {
    display: none;
  }

  .maincontent {
    flex-direction: column;    
}
.card-left{
  max-width: 89%;
}
.title{
  font-size: 34px;
  display: flex;
  align-items: center;
  padding: 0px;    
}
.card1{
  width: 100% !important;
}
.topsearch-bar1{
  padding: 0%;
}
.badge-container{
  display: block;
}
.card-container1 {
  width: 100%;
 flex-direction: column;
 margin-left: 11%;

}
.category-heading{
  font-size: 32px;    
  text-align: left;
  margin-top:auto;
  width: 100%;
  
}
}